import * as React from 'react';
import { createRoot } from 'react-dom/client';

import { PaymentSession, Status } from './interfaces';
import Form from './components/Form';

function App() {
  const [paymentSession, setPaymentSession] = React.useState<PaymentSession>();
  const [status, setStatus] = React.useState<Status>(Status.Loading)

  async function fetchPaymentSession() {
    try {
      const jwt = new URLSearchParams(window.location.search).get('publicToken')
      const snipcartBaseUrl = process.env.API_URL || 'http://localhost:12666';
      const response = await fetch(`${snipcartBaseUrl}/api/public/custom-payment-gateway/payment-session?publicToken=${jwt}`);

      if (!response.ok) {
        setStatus(Status.Failed)
        return
      }
      setPaymentSession(await response.json())
      setStatus(Status.Loaded)
    } catch (error) {
      console.error("Fetch failed:", error);
      setStatus(Status.Failed);
    }
  }

  React.useEffect(() => { fetchPaymentSession() }, [])

  function getContentByStatus(status: Status) {
    switch (status) {
      case Status.Failed:
        return <div className="app__notice">Kunne ikke hente ordren. Der gik måske kludder i noget teknik. Prøv venligst at gå til betaling igen.</div>
      case Status.Loaded:
        return <Form invoice={paymentSession.invoice} paymentSessionId={paymentSession.id} />
      case Status.Loading:
        return <div className="app__notice">Forbereder ordre...</div>
    }
  }

  const websiteBaseUrl = process.env.SHOP_DOMAIN || 'http://localhost:1313';

  return (
    <div className="app">
      <div className="app__body">
        <h1 className="app__title">Kontrollerer ordre</h1>
        <h2 className="app__subtitle">... og sender dig videre til betaling med ePay.</h2>
        {getContentByStatus(status)}
      </div>
      <footer className="app__footer">
        <a href={websiteBaseUrl + '/#/cart'} className="link">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.013 512.013"><path d="M512.013 240.086H54.573l132.64-132.64-22.56-22.72-160 160c-6.204 6.241-6.204 16.319 0 22.56l160 160 22.56-22.56-132.64-132.64h457.44v-32z" /></svg>
          Tilbage til websitet
        </a>
      </footer>
    </div>
  )
}

const domNode = document.getElementById('root');
if (domNode) {
  const root = createRoot(domNode);
  root.render(
      <App />
  )
}
